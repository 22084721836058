<template>
  <enegelai-bot
    v-if="showBot"
    ref="botWidget"
    name="Owner"
    url="bot-service.enegel.ai"
    org-id="owner"
    bot-id="nx6wn34o1v"
    :logo-svg="logoSvg"
    :prefilled-form-fields="userData"
  />
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
  onUnmounted,
  ComponentPublicInstance,
  computed,
  nextTick,
} from 'vue'

import { User } from '@/core'
import { useMappedGetter } from '@/store'
import { useEmitter } from '@/utils/useEmitter'

const messageListener = useEmitter

type CrescendoBotWidget = ComponentPublicInstance & {
  setOpen(status: boolean): void
}

const showBot = ref(false)

const botWidget = ref<CrescendoBotWidget | null>(null)

const logoSvg =
  '<svg width="36" height="36" viewBox="0 0 204 204" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M102 204C158.333 204 204 158.333 204 102C204 45.667 158.333 0 102 0C45.667 0 0 45.667 0 102C0 158.333 45.667 204 102 204Z" fill="black"></path><g class="pin"><path fill-rule="evenodd" clip-rule="evenodd" d="M102 166C102 166 153 139.4 153 102C153 73.8335 130.167 51 102 51C73.8335 51 51 73.8335 51 102C51 139.4 102 166 102 166Z" fill="white"></path><circle cx="102" cy="102" r="24" fill="black"></circle></g></svg>'

const user = useMappedGetter<User | undefined>('session/user')

const userData = computed(() => {
  if (!user.value) return undefined

  const data = {
    email: user.value.email,
    name: `${user.value.firstName} ${user.value.lastName}`,
  }

  return JSON.stringify(data)
})

const showCrescendoWidget = () => {
  showBot.value = true

  nextTick(() => {
    if (botWidget.value) {
      botWidget.value.setOpen(true)
    }
  })
}

onMounted(() => {
  messageListener.on('show-help', showCrescendoWidget)
})

onUnmounted(() => {
  messageListener.off('show-help', showCrescendoWidget)
})
</script>

<style lang="scss">
@use '@/styles/globals';

enegelai-bot {
  --enegelai-bot-max-height: 100vh;
  --enegelai-bot-height-top-margin: 180px;
  --enegelai-bot-height-top-margin-sm: 0px;
  --enegelai-bot-header-color: rgba(255, 250, 250, 0.9);
  --enegelai-bot-header-background: #0044fd;
  --enegelai-bot-message-user-background: rgba(1, 91, 248, 0.8);
  --enegelai-bot-popup-min-height: 50px;
}

enegelai-bot::part(header) {
  @include globals.type('text-title-2');
}

enegelai-bot::part(anchor) {
  display: none;
}
</style>
